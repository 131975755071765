<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<SelectButton v-model="tipoSel" :options="options" optionLabel="tipo" dataKey="id_rubro_tipo"/>						
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="listas" class="p-datatable-sm" v-model:selection="selected" dataKey="id_rubro" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['rubro']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} marcas" :loading="loadingGrid">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Rubros</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="rubro" header="Rubro" :sortable="true"/>
                    <Column field="orden" header="Orden" :sortable="true"/>
                    <Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				
				<Dialog v-model:visible="editDialog" :style="{width: '450px'}" header="Rubro" :modal="true">
					<div class="confirmation-content">
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="subject" style="font-weight: bold;">Tipo</label>
        						<Dropdown v-model="lista.id_rubro_tipo" :options="options" optionLabel="tipo" optionValue="id_rubro_tipo" disabled/>
							</div>      							
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="plantilla" style="font-weight: bold;">Rubro</label><span style="color: red;">*</span>
								<InputText id="rubro" v-model="lista.rubro" :maxlength="100" required="true" autofocus />
							</div>    
							<div class="p-field p-col-12">
								<label class="p-mr-1 p-as-center" for="body" style="font-weight: bold;">Orden</label><span style="color: red;">*</span>
								<InputNumber id="orden" v-model="lista.orden" inputId="integeronly" :min="0"/>
							</div>                                            
						</div>  
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog=false"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec" :disabled="!puedeActualizarRubro"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="enUsoDialog" :style="{width: '450px'}" header="En Uso" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">No es posible remover el rubro <b>[{{ lista.rubro }}]</b> por que tiene registros asociados!</span>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="enUsoDialog = false"/>						
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">Esta seguro que desea eliminar el rubro seleccionado? <br><b>[{{ lista.rubro }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="eliminar" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import BanService from '../../service/ban.service';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {FilterMatchMode} from 'primevue/api';
// import Checkbox from 'primevue/checkbox';

export default {
	setup() {
		return { v$: useVuelidate() }
	},		
	components:
	{
		Loading
	},
	data() {
		return {
			listas: null,
			listaDialog: false,		
			editingCellRows: {},
			editDialog: false,	
            ingresos: '{65E9B4B3-2168-47A9-B646-19A8B71A8684}',
            egresos: '{7C0799CA-FFF2-42BA-B7BE-DB7224F4D8D3}',	
			options: [
					{id_rubro_tipo: '{65E9B4B3-2168-47A9-B646-19A8B71A8684}', tipo: 'Ingresos'}, 
					{id_rubro_tipo: '{7C0799CA-FFF2-42BA-B7BE-DB7224F4D8D3}', tipo: 'Egresos'}
				],
            tipoSel: null,	
			deleteDialog: false,
			enUsoDialog: false,
			lista: {
				id_rubro: '',
				id_rubro_tipo: '',
                rubro: '',
                orden: 0,
				activo: ''						
			},
			selected: null,
			loading: false,
			loadingGrid: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	validations() {
		return {
			lista: { 
				rubro: { required },		
				orden: { required },
			}
		}
	},			
	watch: {
		tipoSel: function () {
			this.cargarInfo();
		}
	},
	computed: {
		puedeActualizarRubro() {
			return !this.v$.lista.$invalid;
		}
	},
	mounted() {
        this.tipoSel = this.options[0];
	},
	methods: {
		async cargarInfo(){
			this.loading = true;
            try {
                var data = await BanService.getRubros(this.$store.state.auth.currentAppCtx, this.tipoSel.id_rubro_tipo);
                this.listas = data;

                this.loading = false;	
            } catch (err) {
				this.loading = false;
                this.listas = null;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }
		},
        formatCurrency(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
        isPositiveNumeric(val) {
			if(this.isNumeric(val)){
				let num = parseFloat(val);
				return num >= 0.00;
			} else {
				return false;
			}
		},	
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},				
		async guardarRec(){
			this.loading = true;	
			try{
                var data = await BanService.registrarRubro(this.$store.state.auth.currentAppCtx, this.lista);

				if(this.lista.id_rubro == '') {
					this.lista.id_rubro = data.id_rubro;
					this.listas.push(this.lista);
				} else {
					for (var i =0; i < this.listas.length; i++) {
						if (this.listas[i].id_rubro == this.lista.id_rubro) {
							this.listas[i] = JSON.parse(JSON.stringify(this.lista));							
							break;
						}
					}	  					
				}
				this.lista = {};

                this.editDialog = false;	
				this.loading = false;	
			} catch (err) {
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
				this.submitted = true;
				this.editDialog = false;
				this.loading = false;
			}
		},
		openNew() {
			this.lista = {
				id_rubro: '',
				id_rubro_tipo: this.tipoSel.id_rubro_tipo,
                rubro: '',
                orden: 0,
				activo: 'S'		
			};
			this.submitted = false;
			this.editDialog = true;
		},
		edit(registro) {
			this.lista = registro;
			this.submitted = false;
			this.editDialog = true;
		},
		async confirmDelete(registro) {
			this.loading = true;
			try{
				this.lista = registro;
				var data = await BanService.rubroEnUso(this.$store.state.auth.currentAppCtx, this.lista);
				this.loading = false;
				if(data.en_uso) {
					this.enUsoDialog = true;
				} else {
					this.deleteDialog = true;
				}
			} catch (err) {
				this.lista = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
		}, 
		async eliminar(registro) {
			this.loading = true;
			try {
                var data = await BanService.removerRubro(this.$store.state.auth.currentAppCtx, this.lista);

				this.listas = this.listas.filter(val => val.id_rubro !== this.lista.id_rubro);
				this.deleteDialog = false;
				this.lista = {};
				this.loading = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'El rubro ha sido eliminado', life: 3000});
			} catch(err) {
				this.deleteDialog = false;
				this.lista = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}			
		},
		confirmDeleteSelected() {
			this.deleteDialog = true;
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
